.sidebar-root {
    background-color: rgb(67, 145, 85);
    min-height: 100vh;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo-wrapper {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin: 0 0 30px;
}

.side-logo {
    margin: 10px auto;
    height: 40px;
    display: table;
}

.list-menu {
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    overflow: hidden;
    flex-grow: 1;
}

.sidebar-list {
    height: 56px;
    line-height: 56px;
    list-style: none;
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    color: #303133;
    padding: 0 20px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;

    &:hover{
        background-color: #2c4a04;
    }
}

.sidebar-list--active {
    height: 56px;
    line-height: 56px;
    list-style: none;
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    color: #303133;
    padding: 0 20px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
    background-color: #FFFFFF1A;
}

.item-link {
    color: #fff;
    font-size: 16px;
    display: inline-block;
    height: 56px;
    width: 100%;
    &:hover {
        text-decoration: none;
    }
}

.item-icon {
    color: #fff;
    font-size: 20px;
    &:hover {
        text-decoration: none;
    }
}

.nav-text {
    font-size: 16px;
    margin-left: 10px;
    color: #fff !important;
}

.logo-image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    & > img {
        flex-grow: 1;
    }
}