.page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px ;
    font-size: large;
    color: #606266;
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 4px;
    label {
        margin: 4px 8px 4px 4px;
    }
}

.input-error {
    border-color: #FF0000;
}

.label-error {
    color: #FF0000;
}

.info-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin: 0 8px 0 0;
}

.info-value {
    color: #000;
    font-size: 16px;
    font-weight: normal;
}

.normal-font {
    font-size: 20px !important;
}