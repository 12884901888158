.page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px ;
    font-size: large;
    color: #606266;
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 4px;
    label {
        margin: 4px 8px 4px 4px;
    }
}

.input-error {
    border-color: #FF0000;
}

.label-error {
    color: #FF0000;
    border-color: #FF0000;
}

.upper-date-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;

    & > div {
        z-index: 2;
    }
}

.lower-date-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;

    & > div {
        z-index: 1;
    }
}

.medication-container {
    &>div {
        z-index: 10;
    }
}

.doctor-container {
    &>div {
        z-index: 15;
    }
}

.patient-container {
    &>div {
        z-index: 20;
    }
}

.notice {
    color: #FF0000;
    font-size: 14px;
}