.page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px ;
    font-size: large;
    color: #606266;
}

.item-icon {
    color: #000;
    font-size: 18px;
    &:hover {
        text-decoration: none;
        color: #007BFF;
    }
}

.info-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin: 0 8px 0 0;
}

.info-value {
    color: #000;
    font-size: 16px;
    font-weight: normal;
}

.normal-font {
    font-size: 20px !important;
}


.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 4px;
    label {
        margin: 4px 8px 4px 4px;
    }
}

.input-error {
    border-color: #FF0000;
}

.label-error {
    color: #FF0000;
}

.dateModalTest {
    max-width: 325px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: #4CAf50;
    
    & >.title {
        font-size: 1.1rem;
        margin-bottom: 8px;
        display: block;
    }

    & >.dateContainer {

        margin: 12px 24px;

        & >.year {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.75;
            display: block;
        }
    
        & >.date {
            font-size: 2.125rem;
            font-weight: 400;
            line-height: 1.235;
            display: block;
        }
    }

}