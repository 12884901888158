.root {
    display: flex;
    flex-direction: row;
}

.logo {
    width: 120px;
    color: red;
    position: absolute;
    left: 50px;
    top: 50px;
}

.doctor-image {
    width: 65%;
    margin-top: 50px;
}

.image-wrapper {
    height: 100vh;
    background-color: #439155;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login-wrapper {
    width: 350px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0;
}

.login-container {
    height: 100vh;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.description {
    color: #666;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 20px;
    line-height: 1.4;
}

.login-form-input-item {
    margin-bottom: 15px;
}

.button-wrapper {
    margin-top: 10px;
}

.login-button {
    background-color: #D8455F;
    color: #fff;
    border-color: #D8455F;
}

.register-button {
    margin-left: 8px;
}
// .forget-button {
//     display: inline-block;
//     font-weight: 400;
//     color: #212529;
//     text-align: center;
//     vertical-align: middle;
//     -webkit-user-select: none;
//     user-select: none;
//     background-color: transparent;
//     border: 1px solid transparent;
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     line-height: 1.5;
//     border-radius: 0.25rem;
//     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     color: #333;
//     &:hover{
//         text-decoration: underline;
//     }
// }


.login-form-input-item {
    background-color: #F7F7F7;
    width: 100%;
    background-image: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    border: none;
    font-size: medium;
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active  {
        box-shadow: 0 0 0 30px #F7F7F7 inset !important;
        -webkit-box-shadow: 0 0 0 30px #F7F7F7 inset !important;
    }
}

.login-form-input-item ::placeholder {
    color: #555;
    opacity: 1;
}